// Colors
$default-color: #424242;
$secondary-color: #999999;

$nav-bar-color: #303030;
$border-color: #c9c9c9;
$background-color: #e7e7e7;

$light-bg-color: #f7f7f7;
$light-bg-color-darker: darken($light-bg-color, 10%);

$border-color: #cccccc;
$border-color-dark: #707070;

$white: #ffffff;
$black: #000000;
$yellow: #efd443;
$teal-color: #009aa6;
$teal-font-color: #0e6377;

$dark-grey-60: #666666;
$dark-grey-30: #b3b3b3;
$dark-grey-20: #cccccc;
$dark-grey-10: #494343;
$dark-grey-5: #f2f2f2;

$primary-color: #1f88e5;
$primary-color-hover: #e3f0fb;
$warning-color: #f8b24b;
$warning-color-light: #fdedd6;
$warning-color-hover: #fd9426;
$danger-color: #f34663;
$danger-color-light: #fcd5db;
$danger-color-hover: #ff002b;
$danger-color-bg: #fdd6dd;
$success-color: #7ac943;
$success-color-hover: #43a047;

$purple-dark: #5e34b1;
$purple-dark-hover: #522d9b;
$purple-medium: #9575cd;
$purple-medium-hover: #8266b4;
$purple-light: #d1c4e9;
$purple-light-border: #c9bae5;
$purple-light-hover: #b7accc;

$blue-dark: #0d47a1;

$box-shadow-default: rgba(0, 0, 0, 0.16);
$border-radius-default: 0.1875rem;

$pagination-hover: #e7f2fd;

$grey-1: #e7e7e7;
$grey-2: #cccccc;
$grey-3: #999999;
$grey-4: #666666;
$grey-5: #424242;

// Font family
$font-default-family: 'Open Sans', sans-serif;

// Font weights
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-extrabold: 800;

// Width & height
$page-width: 1600px;
$section-min-height: 15.625rem;
