// Dashboard
@mixin t-card-header {
    font-weight: $font-weight-bold;
    font-size: calcRem(21px);
    line-height: calcRem(28px);
    letter-spacing: 0.02em;
    color: $grey-5;
}

@mixin t-ETS {
    font-weight: $font-weight-semibold;
    font-size: calcRem(85px);
    line-height: 1;
    letter-spacing: 0;
    color: $grey-5;
}

@mixin t-card-sub-header {
    font-weight: $font-weight-semibold;
    font-size: calcRem(16px);
    line-height: calcRem(22px);
    letter-spacing: 0.02em;
    color: $grey-5;
}

@mixin t-card-sub-header-grey {
    @include t-card-sub-header;
    color: $grey-3;
}

@mixin t-card-sub-header-small {
    font-weight: $font-weight-semibold;
    font-size: calcRem(14px);
    line-height: calcRem(19px);
    letter-spacing: 0.02em;
    color: $grey-5;
}

@mixin t-card-sub-header-small-grey {
    @include t-card-sub-header-small;
    color: $grey-3;
}

@mixin t-card-body {
    font-weight: $font-weight-semibold;
    font-size: calcRem(12px);
    line-height: calcRem(17px);
    letter-spacing: 0.02em;
    color: $grey-5;
}

@mixin t-card-body-regular {
    font-weight: $font-weight-normal;
    font-size: calcRem(12px);
    line-height: calcRem(17px);
    letter-spacing: 0.02em;
    color: $grey-5;
}

@mixin t-card-body-grey {
    @include t-card-body;
    color: $grey-3;
}

@mixin t-card-body-extra-bold {
    @include t-card-body;
    font-weight: $font-weight-extrabold;
}

@mixin t-card-body-white {
    @include t-card-body;
    color: $white;
}

// Asset tile
@mixin t-tile-score {
    font-weight: $font-weight-semibold;
    font-size: calcRem(18px);
    line-height: calcRem(24px);
    letter-spacing: 0;
    color: $white;
}

@mixin t-tile-header {
    font-weight: $font-weight-semibold;
    font-size: calcRem(13px);
    line-height: calcRem(18px);
    letter-spacing: 0;
    color: $white;
}

@mixin t-tile-sub-header {
    font-weight: $font-weight-semibold;
    font-size: calcRem(15px);
    line-height: calcRem(20px);
    letter-spacing: 0;
    color: $grey-5;
}

@mixin t-card-button {
    @include t-card-body;
    color: $primary-color;
}

// Navigation
@mixin t-anchor-tag {
    font-weight: $font-weight-bold;
    font-size: calcRem(16px);
    line-height: calcRem(22px);
    letter-spacing: 0;
    color: $grey-1;
}

@mixin t-v-small {
    font-weight: $font-weight-normal;
    font-size: calcRem(10px);
    letter-spacing: 0;
    color: $grey-3;
}
