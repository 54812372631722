@import './font';
@import './reboot';
@import './mixins';
@import './variables';
@import './typography';
@import '../components/components';

/* Globals */
body,
html {
    font-family: $font-default-family;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    @include large {
        font-size: calcRem(14px);
    }

    @include medium {
        font-size: calcRem(13px);
    }
}

#root {
    scroll-behavior: smooth;
    min-height: 100vh;
    background-color: $background-color;
    @include x-large {
        overflow: auto;
    }
}

.root {
    &--selected-location {
        background-color: $dark-grey-30 !important;
    }
}

.tippy-box,
.tippy-content {
    padding: 0 !important;
    background: none;
}

@keyframes loading-indicator {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
