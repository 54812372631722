@mixin threat-hover-states {
    background-color: inherit;

    &--medium {
        background-color: $warning-color-hover;
    }

    &--high {
        background-color: $danger-color-hover;
    }
}

// Breakpoints
@mixin x-large {
    @media (max-width: 1615px) {
        @content;
    }
}

@mixin large {
    @media (max-width: 1400px) {
        @content;
    }
}

@mixin medium {
    @media (max-width: 980px) {
        @content;
    }
}

@mixin small {
    @media (max-width: 660px) {
        @content;
    }
}

@mixin squashed {
    @media (max-height: calc($standard-grid-min-height + 5.625rem)) {
        @content;
    }
}

// Grids
$standard-grid-min-height: 740px;

@mixin standard-grid {
    grid-gap: 1rem;
    height: calc(100vh - 5.625rem);
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(3, 1fr);
    padding-bottom: 1.875rem;
    min-height: $standard-grid-min-height;

    & > * {
        @include standard-box-shadow;
    }

    @include x-large {
        padding: 0 0.9375rem 1.875rem 0.9375rem;
    }

    @include large {
        grid-template-columns: repeat(9, 1fr);
        grid-auto-rows: minmax(19.375rem, min-content);
        min-height: auto;
        height: auto;
    }

    @include medium {
        grid-template-columns: repeat(6, 1fr);
        grid-template-rows: 19.97rem;
        grid-auto-rows: minmax(19.375rem, min-content);
    }

    @include small {
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: 19.97rem;
        grid-auto-rows: minmax(19.375rem, min-content);
    }
}

// Common resets
@mixin button-reset {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
}

@mixin a-reset {
    color: inherit;
    text-decoration: inherit;

    &:hover {
        color: inherit;
        text-decoration: inherit;
    }
}

@mixin ul-reset {
    list-style: none;
    padding: 0;
    margin: 0;
}

@mixin standard-box-shadow {
    box-shadow: 0px 1px 2px $box-shadow-default;
}

@function calcRem($size) {
    $remSize: $size / 16px;
    @return #{$remSize}rem;
}

%text-truncate-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
