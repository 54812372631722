.zoom-switch {
    @include t-card-sub-header-small-grey;
    display: flex;
    align-items: center;

    .zoom-switch-label {
        margin-right: calcRem(10px);
    }

    .form-check-input {
        cursor: pointer;
    }

    button {
        @include button-reset;
        @include t-card-body-extra-bold;
        color: $default-color;
        border-top: calcRem(1px) solid $border-color;
        border-bottom: calcRem(1px) solid $border-color;
        border-radius: 0;
        width: calcRem(25px);
        height: calcRem(25px);
        background-color: $white;

        &.first {
            border-left: calcRem(1px) solid $border-color;
            border-radius: calcRem(3px) 0 0 calcRem(3px);
        }

        &.last {
            border-right: calcRem(1px) solid $border-color;
            border-radius: 0 calcRem(3px) calcRem(3px) 0;
        }

        &.active {
            background-color: $primary-color;
            color: $white;

            &:hover {
                background-color: darken($primary-color, 10%);
            }
        }

        &:hover {
            background-color: $primary-color-hover;
        }

        &:disabled {
            color: $grey-4;
        }
    }
}

.my-view-container {
    grid-column: span 9;
    grid-row: span 3;

    &__header {
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 999;
        border-bottom: calcRem(1px) solid $box-shadow-default;
    }

    &__title {
        margin-right: auto;
        color: $default-color;
    }

    &__body {
        overflow: auto;
        z-index: 0;
        background-color: $light-bg-color;
    }

    &__info {
        margin-left: auto;
    }

    .container-name {
        @include t-card-sub-header;
        margin-right: calcRem(30px);
    }

    .details-button-wrap {
        margin-bottom: calcRem(15px);
    }

    .details {
        padding: calcRem(2px) calcRem(10px);
        text-transform: uppercase;
    }
}

.my-container-node-squares {
    @include t-tile-score;
    margin-bottom: calcRem(60px);

    .node-square--outline {
        float: left;
        border: calcRem(2px) solid;
        border-radius: calcRem(7px);

        &--high {
            border-color: $purple-dark;
        }

        &--medium {
            border-color: $purple-medium;
        }

        &--low {
            border-color: $purple-light-border;
        }
    }

    .node-square--threat {
        &--medium {
            background-color: $warning-color;

            &:hover {
                background-color: darken($warning-color, 10%);
            }
        }

        &--high {
            background-color: $danger-color;

            &:hover {
                background-color: darken($danger-color, 10%);
            }
        }
    }
}

.asset-square-wrapper {
    &:focus {
        outline: none;
    }
}

.asset-details-popover {
    min-width: calcRem(350px);
    margin-bottom: calcRem(60px);
    margin-top: calcRem(-2px);
    border-radius: calcRem(3px);
    border: 1px solid $border-color;
    background-color: $white;
    box-shadow: 0px 1px 2px $box-shadow-default;

    &-text {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 calcRem(10px);
        width: 100%;
    }

    .threat-score {
        @include t-tile-score;
        position: relative;
        padding: 4px 10px;
        border-top-left-radius: $border-radius-default;
        border-bottom-left-radius: $border-radius-default;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-right: 1px solid $white;
        min-width: calcRem(42px);
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: inherit;

        &--medium {
            background-color: $warning-color;
        }

        &--high {
            background-color: $danger-color;
        }
    }

    &--show-arrow {
        margin-left: calcRem(13px);

        &:before {
            content: '';
            position: absolute;
            width: calcRem(25px);
            height: calcRem(25px);
            left: calcRem(1px);
            top: calcRem(2px);
            transform: rotate(45deg);
            border-radius: calcRem(5px);
            box-shadow: 0px 1px 2px $box-shadow-default;
        }

        &.asset-details-popover--LOW {
            &:before {
                background-color: $purple-light;
            }
        }

        &.asset-details-popover--MEDIUM {
            &:before {
                background-color: $purple-medium;
            }
        }

        &.asset-details-popover--HIGH {
            &:before {
                background-color: $purple-dark;
            }
        }

        &.asset-details-popover--medium {
            &:before {
                background-color: $warning-color;
            }
        }

        &.asset-details-popover--high {
            &:before {
                background-color: $danger-color;
            }
        }
    }

    &__last-event {
        display: flex;
        justify-content: space-between;
        margin: 0 calcRem(10px) calcRem(10px) calcRem(30px);
        color: $default-color;
    }
}

.asset-square-popover {
    .popover {
        z-index: 1;
        min-width: calcRem(368px);

        &-inner {
            width: calcRem(368px);
        }
    }
}

.asset-square-tooltip {
    .tooltip {
        z-index: 1;
    }

    .tooltip-inner {
        color: $default-color;
        background-color: $white;
        box-shadow: 0px 1px 4px $box-shadow-default;
    }
}

.asset-details-popover__title {
    @include t-tile-header;
    color: $white;
    border-color: transparent;
    width: 100%;
    border: none;
    border-top-left-radius: calcRem(3px);
    border-top-right-radius: calcRem(3px);
    border: 0;
    box-shadow: 0px 1px 2px $box-shadow-default;
    display: flex;
    padding: 0;

    .name {
        @include t-tile-sub-header;
        color: $purple-dark;
    }

    .value {
        text-transform: capitalize;
        color: $purple-dark;
    }

    &--low {
        background-color: $purple-light;
    }

    &--medium {
        background-color: $purple-medium;

        .name {
            color: $white;
        }

        .value {
            color: $purple-light;
        }
    }

    &--high {
        background-color: $purple-dark;

        .name {
            color: $white;
        }

        .value {
            color: $purple-medium;
        }
    }
}

.asset-details-popover__body {
    padding: calcRem(10px) calcRem(10px) 0 calcRem(10px);

    hr {
        border-top: 1px solid $dark-grey-5;
        margin: calcRem(10px) 0 calcRem(10px) calcRem(30px);

        &:last-of-type {
            margin: calcRem(10px) 0 0 calcRem(30px);
        }
    }

    &__ipAddress {
        @include t-tile-sub-header;
        margin-left: calcRem(30px);
        color: $default-color;
    }

    &__collapse-container {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__collapse-inner {
        @include t-card-body-grey;
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 0 calcRem(10px);
    }

    &__peer-group {
        color: $secondary-color;
    }

    &__threshold-group {
        text-align: right;
        display: flex;
        align-items: center;
    }

    &__threshold-text {
        &--high {
            color: $danger-color;
        }

        &--medium {
            color: $warning-color;
        }

        &--ok {
            color: $secondary-color;
        }
    }
}

.asset-details-popover__footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-color: $white;
    padding: calcRem(2px);
    border-top: calcRem(5px);

    .btn-link {
        @include t-card-button;
        text-transform: uppercase;
        text-decoration: none;

        svg {
            margin-right: calcRem(3px);
        }

        &:hover {
            background-color: rgba($primary-color, 0.1);
        }
    }
}

.my-container-node-squares {
    display: grid;
    grid-template-columns: repeat(auto-fit, calcRem(33px));
    grid-gap: calcRem(10px);
    margin-top: calcRem(10px);

    .node-square {
        cursor: pointer;
        width: calcRem(25px);
        height: calcRem(25px);
        text-align: center;
        line-height: calcRem(25px);
        border-radius: calcRem(3px);
        display: flex;
        justify-content: center;
        align-items: center;
        color: $white;
        margin: calcRem(4px);

        &--outlined {
            margin: calcRem(2px);
        }

        &--high {
            background-color: $purple-dark;

            &:hover {
                background-color: darken($purple-dark, 10%);
            }
        }

        &--medium {
            background-color: $purple-medium;

            &:hover {
                background-color: darken($purple-medium, 10%);
            }
        }

        &--low {
            color: $purple-dark;
            background-color: $purple-light;

            &:hover {
                background-color: darken($purple-light, 10%);
            }
        }
    }
}

.node-square-popover {
    .popover {
        z-index: 1;
    }
}

.my-container-node-expanded {
    margin-top: calcRem(30px);
}
